export const  ServicesData = [
  {
    Images: "/Asstes/Images/Group 1000005950.svg",
    label: "Software<br>Development",
    href: "/services/software-development",
  },
  {
    Images: "/Asstes/Images/Group 1000005950 (1).svg",
    label: "App <br>Development",
    href: "app-development",
  },
  {
    Images: "/Asstes/Images/Group 1000005950 (2).svg",
    label: "Web<br>Development",
    href: "/services/web-development",
  },
  {
    Images: "/Asstes/Images/Group 1000005950 (3).svg",
    label: "Branding<br>Services",
    href: "/services/branding-services",
  },
  {
    Images: "/Asstes/Images/Group 1000005950 (4).svg",
    label: "Digital<br>Marketing",
    href: "/services/digital-marketing",
  },
  {
    Images: "/Asstes/Images/Group 1000005950 (5).svg",
    label: "Cloud <br>Computing Services",
    href: "/services/cloud-services",
  },
];
export const ExpreanseData = [
  {
    images: "/Asstes/Images/Group 1000006010 (9).svg",
  },
  {
    images: "/Asstes/Images/Group 1000006011.svg",
  },
  {
    images: "/Asstes/Images/Group 1000006012.svg",
  },
];
export const ButtonData = [
  {
    button: "/Asstes/Images/Group 1000006013.svg",
  },
  {
    button: "/Asstes/Images/Group 1000006014.svg",
  },
  {
    button: "/Asstes/Images/Services/commitment.png",
  },
  {
    button: "/Asstes/Images/Group 1000006016.svg",
  },
];
export const DataTestomonial = [
  {
    images: [
      "/Asstes/Images/Star 4.svg",
      "/Asstes/Images/Star 4.svg",
      "/Asstes/Images/Star 4.svg",
      "/Asstes/Images/Star 4.svg",
      "/Asstes/Images/Star 4.svg",
    ],
  },
  
];
export const MissionVissionData = [
  {
    VissionImages: "/Asstes/Images/Services/science 3.svg",
    Heading: "OUR VISION",
    Answer:
      "To be a leading provider of innovative IT solutions, recognized for our expertise, reliability, and commitment to customer satisfaction. We strive to be the go-to choice for businesses seeking cutting-edge technology and exceptional service. Our goal is to build long-lasting partnerships with our clients, based on trust, collaboration, and mutual success. We aim to continuously push the boundaries of what is possible in the field of software development, delivering solutions that exceed expectations and drive positive outcomes for our clients. ",
  },
  {
    VissionImages: "/Asstes/Images/Services/science 3.svg",
    Heading: "OUR MISSION",
    Answer:
      "To offer state-of-the-art software solutions that enable companies to realise their greatest potential. We are dedicated to using cutting-edge technology to promote sustainable growth, improve client experiences, and increase efficiency. We provide specialised solutions that produce quantifiable outcomes by fully comprehending the particular requirements and difficulties of each of our consumers. Our goal is to be a reliable resource for companies looking to thrive in the digital sphere.",
  },
  {
    VissionImages: "/Asstes/Images/Services/science 3.svg",
    Heading: "OUR HISTORY",
    Answer:
      "The success of Carecone is evidence of the value of creativity, teamwork, and an unwavering commitment to quality. With the support of Reptile India's experience, Carecone is well-positioned to expand and leave a lasting mark on the IT sector.",
  },
  
];
export const TeamsData = [
  {
   
    imagesBack:'/Asstes/Images/image 58.png',
    TEamNameBack:'Dr. Nidhi Chaudhary',
    Team_workBack: "business Head ",
    Team_textBack: "STRATAGIC",
    Team_valueBack: "Business mind",
    images: "/Asstes/Images/AboutImages/Haresh Mehars.png",
    Team_text: "VISIONARY",
    Team_value: "DESIGNER",
    TEamName: "Harsh Mehra",
    Team_work: "PRODUCT DESIGNER  ",
  },
  {
   
    imagesBack:'/Asstes/Images/AboutImages/image 36.png',
    TEamNameBack:'Pawan singh',
    Team_workBack:'Graphic DESIGNER ',
    Team_textBack:'STRATAGIC',
    Team_valueBack:'DESIGNER',
    images: "/Asstes/Images/AboutImages/image 33.png",
    Team_text: "STRATAGIC",
    Team_value: "MARKETING GENIUS",
    TEamName: "yashu Thakur",
    Team_work: "Marketing head",
  },
  {
   
    imagesBack:'/Asstes/Images/AboutImages/IMG20231108231117-removebg-preview (1) 2.png',
    TEamNameBack:'Trapti Satsangi ',
    Team_workBack:'PRODUCT Manger',
    Team_textBack:'ENGAGING',
    Team_valueBack:'Manager',
    images: "/Asstes/Images/AboutImages/image 35.png",
    Team_text: "VISIONARY  ",
    Team_value: "DEVELOPER",
    TEamName: "Vaibhav Nayak",
    Team_work: "Android developer",
  },
  {
   
    imagesBack:'/Asstes/Images/AboutImages/image 38.png',
    TEamNameBack:'Gopichand Bandi',
    Team_workBack:'Android developer',
    Team_textBack:'VISIONARY',
    Team_valueBack:'DEVELOPER',
    images: "/Asstes/Images/AboutImages/image (4).png",
    Team_text: "VISIONARY",
    Team_value: "DEVELOPER",
    TEamName: "Pravas chander Nayak",
    Team_work: "Senior developer",
  },
  {
   
    imagesBack:'/Asstes/Images/AboutImages/image (2).png',
    TEamNameBack:'Nikshit Bhateja',
    Team_workBack:'iOS & Fluter Developer',
    Team_textBack:'VISIONARY',
    Team_valueBack:'DEVELOPER',
    images: "/Asstes/Images/AboutImages/image (3).png",
    Team_text: "Warrior",
    Team_value: "cloud engineer ",
    TEamName: "YUDHISTHER",
    Team_work: "Cloud Computing Expert",
  },
  {
   
    imagesBack:'/Asstes/Images/AboutImages/image (1).png',
    TEamNameBack:'Neelmani shukla',
    Team_workBack:'Senior developer',
    Team_textBack:'VISIONARY',
    Team_valueBack:'DEVELOPER',
    images: "/Asstes/Images/AboutImages/Haresh Mehars.png",
    Team_text: "VISIONARY",
    Team_value: "DESIGNER",
    TEamName: "Harsh Mehra",
    Team_work: "PRODUCT DESIGNER  ",
  },
 
];
export const ProjectDattata = [
  {
   
    imagesBack:'/Asstes/Images/image 58.png',
    TEamNameBack:'Dr. Nidhi Chaudhary',
    Team_workBack: "business Head ",
    Team_textBack: "STRATAGIC",
    Team_valueBack: "Business mind",
    images: "/Asstes/Images/AboutImages/Haresh Mehars.png",
    Team_text: "VISIONARY",
    Team_value: "DESIGNER",
    TEamName: "Harsh Mehra",
    Team_work: "PRODUCT DESIGNER  ",
  },
  {
   
    imagesBack:'/Asstes/Images/AboutImages/image 36.png',
    TEamNameBack:'Pawan singh',
    Team_workBack:'Graphic DESIGNER ',
    Team_textBack:'STRATAGIC',
    Team_valueBack:'DESIGNER',
    images: "/Asstes/Images/AboutImages/image 33.png",
    Team_text: "STRATAGIC",
    Team_value: "MARKETING GENIUS",
    TEamName: "yashu Thakur",
    Team_work: "Marketing head",
  },
  {
   
    imagesBack:'/Asstes/Images/AboutImages/IMG20231108231117-removebg-preview (1) 2.png',
    TEamNameBack:'Trapti Satsangi ',
    Team_workBack:'PRODUCT Manger',
    Team_textBack:'ENGAGING',
    Team_valueBack:'Manager',
    images: "/Asstes/Images/AboutImages/image 35.png",
    Team_text: "VISIONARY  ",
    Team_value: "DEVELOPER",
    TEamName: "Vaibhav Nayak",
    Team_work: "Android developer",
  },

 
];
export const MobileButtonServices = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "NODE JS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "PHP",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "PYTHON",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "LARAVEL",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "EXPRESS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "",
  },
];
export const MobileButtonSoftware = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "NODE JS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "PHP",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "PYTHON",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "LARAVEL",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "EXPRESS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "CODEIGNITER",
  },
];
export const MobileButtonMobileApp  = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "JAVA",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "KOTLIN",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "FLUTTER",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "SHIFT",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "DART",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "REACT NATIVE ",
  },
];
export const MobileButtonWeb = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "HTML / CSS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "BOOTSTRAP",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "REACT jS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "WORDPRESS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "RUBY",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "ANGULAR",
  },
];
export const MobileButtonCloud = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "AWS CLOUD",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "JENKINS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "DOCKER",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "GITHUB",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "ORACLE CLOUD",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "DIGITALOCEAN",
  },
];
export const MobileButtonBranding = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: " ANALYTICS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "VALUE",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "BRAND AUDIT",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "UNIQUE SELLING BENEFIT",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "MARKETING MATERIAL",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "LOGO DESIGN",
  },
];
export const MobileButtonDigtal = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "SOCIAL MEDIA MARKETING",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "SEARCH ENGINE OPTIMIZATION",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "EMAIL MARKETING",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "GOOGLE ADWORDS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "META ADS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: " PAY-PER-CLICK",
  },
];
export const FortFolioData = [
  {
    slug:"gola-sizzler-mobile",
    imagesFortFolio: "/Asstes/Images/Services/imgpsh_fullsize_anim (3).gif",
    ProjectName: "Gola Sizzler",
    ProjectAnswer: "Mobile application Design & Development",
    Label: [
      {
        TimerLabel: "60%",
        Increase: "Increase traffic ",
      },
      {
        TimerLabel: "50%",
        Increase: "Increase USER",
      },
    ],
  },
  {
    slug:"gola-sizzler-website",
    imagesFortFolio: "/Asstes/Images/Services/imgpsh_fullsize_anim.gif",
    ProjectName: " Gola Sizzler",
    ProjectAnswer: "Website Design & Development",
    Label: [
      {
        TimerLabel: "40%",
        Increase: "Increase traffic ",
      },
      {
        TimerLabel: "50%",
        Increase: "Increase in website traffic ",
      },
      
    ],
  },
  {
    slug:"annant-education",
    imagesFortFolio: "/Asstes/Images/Services/imgpsh_fullsize_anim (1).gif",
    ProjectName: " Annant Education",
    ProjectAnswer: "Website Design & Development",
    Label: [
      {
        TimerLabel: "70%",
        Increase: "Increase traffic",
      },
      {
        TimerLabel: "40%",
        Increase: "Increase in website traffic",
      },
     
    ],
  },
  {
    slug:"pay-n-earn",
    imagesFortFolio: "/Asstes/Images/Services/imgpsh_fullsize_anim (6).gif",
    ProjectName: " Pay N Earn",
    ProjectAnswer: "Mobile application Design & Development",
    Label: [
      {
        TimerLabel: "70%",
        Increase: "Increase traffic",
      },
      {
        TimerLabel: "30%",
        Increase: "Increase in website traffic",
      },
    ],
    ImagesSlider:[
      {
        ImagesPaynern: "/Asstes/Images/Services/Pay_N_Earn/Group 1000006219.png",
      },
    ]
  },
  {
    slug:"suraksha-code",
    imagesFortFolio: "/Asstes/Images/Services/imgpsh_fullsize_anim (2).gif",
    ProjectName: "Suraksha Code",
    ProjectAnswer: "Mobile application Design & Development",
    Label: [
      {
        TimerLabel: "50%",
        Increase: "Increase traffic",
      },
      {
        TimerLabel: "25%",
        Increase: "Increase in website traffic",
      },
    
    ],
  },
  {
    slug:'gowisekart',
    imagesFortFolio: "/Asstes/Images/Services/imgpsh_fullsize_anim (4).gif",
    ProjectName: " Go wise cart",
    ProjectAnswer: "Mobile application Design & Development",
    Label: [
      {
        TimerLabel: "30%",
        Increase: "Increase traffic",
      },
      {
        TimerLabel: "30%",
        Increase: "Increase in website traffic",
      },
    ],
  },  
];
export const Mobile_buttom_data = [
  {
    Buttom_images: "/Asstes/Images/Services/Group 1000006011.svg",
  },
  {
    Buttom_images: "/Asstes/Images/Services/Group 1000006012 (2).svg",
  },
  {
    Buttom_images: "/Asstes/Images/Services/Group 1000006014.svg",
  },
  {
    Buttom_images: "/Asstes/Images/Services/Group 1000006063.svg",
  },
  {
    Buttom_images: "/Asstes/Images/Services/Group 1000006064.svg",
  },
  {
    Buttom_images: "/Asstes/Images/Services/Group 1000006062.svg",
  },
]
export const ProjectAlldataServices = [
  {
    video:'/Asstes/Images/AboutImages/Frame 1000006169 (1).png',
    name:'GOLA SIZZLER',
    Name2:'WEBSITE',
    slug:'gola-sizzler-website'

  },
  {
    video:'/Asstes/Images/AboutImages/Frame 1000006155.png',
    name:'GOLA SIZZLER',
    Name2:'APPLICATION',
    slug:'gola-sizzler-mobile'
  },
  {
    video:'/Asstes/Images/AboutImages/Frame 1000006169.png',
    name:'ANNANT EDUCATION',
    Name2:'WEBSITE',
    slug:'annant-education'
  },
]
export const settings = {
  dots:false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  autoplaySpeed:2000,
  verticalSwiping: true,
};
export const people = [
  {
    id: 1,
    image:
      '/Asstes/MobileImages/Logo (3).png',
    name: 'Mr. Aakash',
    quote:
      'Carecone Technologies Pvt Ltd did a fantastic job working on our Suraksha Code mobile application. Throughout the process, their team demonstrated professionalism, responsiveness, & support.',
  },
  {
    id: 2,
    image:
      '/Asstes/MobileImages/Logo (2).png',
    name: 'Mr. Vivek',
    quote:
      'Having collaborated with Reptile India on a number of projects, Ive always been pleased with their professionalism and knowledge.',
  },
  {
    id: 3,
    image:
      '/Asstes/MobileImages/Logo (5).png',
    name: 'Mr. Bhojveer Singh',
    quote:
      'Working with the Reptile India team was a breeze. They were quick to adjust to changes in the scope of our project and consistently attentive to our needs.',
  },
  {
    id: 4,
    image:
      '/Asstes/MobileImages/Logo (4).png',
    name: 'Mr. Vishnu Gola',
    quote:
      'Although I was first dubious about outsourcing app development, Im really happy that I went with Reptile India.',
  },
  {
    id: 5,
    image:
      '/Asstes/MobileImages/Abhinash.png',
    name: 'Mr. Avinash',
    quote:
      'There is excellent customer service at Reptile India. They were always willing to talk to us and respond to our inquiries.',
  },
  {
    id: 6,
    image:
      '/Asstes/MobileImages/Logo (1).png',
    name: 'Mr .  Ajay Sinha',
    quote:
      'In every aspect, Reptile India surpassed our expectations. They succeeded in meeting both deadlines and budgets while producing excellent software.',
  },
];
export const ProductsButtonTracko  = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "Real-time Location Tracking",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "Task Management",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "Attendance Management",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "Leave Lanagement",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "HRMS",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "Order form ",
  },
];

export const Productdata = [
  {
    id: "reptile-tracko",
    HeaderImages:'/Asstes/Images/AboutImages/Tracko.png',
    TrackoImages: "/Asstes/Images/Services/background_gola_3 (1).png",
    TrackoName: "TRACKO",
    HeadingFeatures:' WITH REPTILE TRACKO WORK SMARTER!',
    TrackoInformation:'Tracko is a feature-rich staff monitoring program made to improve efficiency and optimize corporate processes. ',
    AboutProductsInformation:'Tracko is a feature-rich smartphone application made to increase staff efficiency and optimize corporate processes. Trackos robust features provide smooth attendance management, effective task management, and real-time tracking.',
    AboutImages:'/Asstes/Images/AboutImages/TrackoAbout.png',
    FeaturesInforHeading1:'Attendance Management',
    Features1Infor:'The attendance management tool in Tracko is made to make tracking attendance on your team easier. You can quickly record and manage staff attendance with our user-friendly software, doing away with the necessity for manual timekeeping.',
    Features1InforImages:'/Asstes/Images/AboutImages/FEatures1Images.png',
    FeaturesInforHeading2:'Leave Management',
    Features2Infor:'With Tracko Leave Management function, you can ensure maximum efficiency and transparency in your business by streamlining the leave application and approval process. Employees may simply submit leave requests through Tracko, and supervisors can quickly examine, approve, or deny them.',
    FeaturesInforHeading3:'Task Management',
    Features2InforImages:'/Asstes/Images/AboutImages/Features2InforImages.png',
    Features3Infor:'Ever experienced overwhelm from an endless to-do list? The task management function in Tracko might help you out. You can easily prioritize, organize, and manage your chores with its user-friendly layout and robust functionality, making sure that nothing gets missed.',
    Features3InforImages:'/Asstes/Images/AboutImages/TrackoTask.png',
    DesignImages3:'/Asstes/Images/AboutImages/background_tracko_3.png',
    DesignImages4:'/Asstes/Images/AboutImages/TrackoBanner.png',
    Features1:'Real-time Location Tracking',
    Features2:'Task Management',
    Features3:'Attendance Management',
    Features4:'Leave Management',
    Features5:'HRMS',
    Features6:'Order form',
    ProductImages:'/Asstes/Images/AboutImages/science 3.svg',
    HrefLinkPlay:'https://play.google.com/store/apps/details?id=com.careconetechnologies.reptiletrackinfo&hl=en',
    HrefLinkApp:'https://apps.apple.com/in/app/reptile-tracko/id6504335178',
    PlaystoreImages:'/Asstes/Images/AboutImages/Playstore.png',
    AppStoreImages:'/Asstes/Images/AboutImages/AppStore.png',
    PlanHeading:' CHOOSE YOUR PLAN ',
    PlanButtonMonth:'Monthly',
    PlanButtonYeayly:'Yearly',
    PlanSubscriptionsMonth:[
      {
        BasicPlan:'Basic Plan ',
        BasicPlanPrices:'300 Per user/Month',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        
        Features:[
          {
            labelFeatures:'Employee Tracking',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Task Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Attendance Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Leave Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Order Management',
            FeaturesFaIcon:'fa fa-check',
          },
         
          {
            labelFeatures:'Holiday Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Up to 5 Users',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Minimum 10 Employees',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Basic customer support',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Quarterly',
            FeaturesFaIcon:'fa fa-check',
          },
        ],
        
      },
      {
        BasicPlan:'Basic Plan Pro ',
        BasicPlanPrices:'350 Per user/Month',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Employee Tracking',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Task Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Attendance Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Leave Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Order Management',
            FeaturesFaIcon:'fa fa-check',
          },
         
          {
            labelFeatures:'Holiday Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Up to 20 Users',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Minimum 10 Employees',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Priority customer support',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Quarterly',
            FeaturesFaIcon:'fa fa-check',
          },
        ],
      },
      {
        BasicPlan:'Standard Plan ',
        BasicPlanPrices:'500 Per user/Month',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Employee Tracking',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Task Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Attendance Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Leave Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Order Management',
            FeaturesFaIcon:'fa fa-check',
          },
         
          {
            labelFeatures:'Holiday Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Payroll',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Up to 5 Users',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Minimum 10 Employees',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Basic customer support',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Quarterly',
            FeaturesFaIcon:'fa fa-check',
          },
        ],
      },
      {
        BasicPlan:'Standard Plan Pro ',
        BasicPlanPrices:'550 Per user/Month',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Employee Tracking',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Task Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Attendance Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Leave Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Order Management',
            FeaturesFaIcon:'fa fa-check',
          },
         
          {
            labelFeatures:'Holiday Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Payroll',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Up to 20 Users',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Minimum 10 Employees',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Priority customer support',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Quarterly',
            FeaturesFaIcon:'fa fa-check',
          },
        ],
      },
      
    ],
    PlanSubscriptionsYearly:[
      {
        BasicPlan:'Premium Plan',
        BasicPlanPrices:'3,000 Per user/Year',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Employee Tracking',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Task Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Attendance Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Leave Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Order Management',
            FeaturesFaIcon:'fa fa-check',
          },
         
          {
            labelFeatures:'Holiday Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Up to 5 Users',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Minimum 10 Employees',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Basic customer support',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Yearly',
            FeaturesFaIcon:'fa fa-check',
          },
         
        ],
      },
      {
        BasicPlan:'Premium Plan Pro',
        BasicPlanPrices:'3,500 Per user/Year',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Employee Tracking',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Task Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Attendance Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Leave Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Order Management',
            FeaturesFaIcon:'fa fa-check',
          },
         
          {
            labelFeatures:'Holiday Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Up to 20 Users',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Minimum 10 Employees',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Priority customer support',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Yearly',
            FeaturesFaIcon:'fa fa-check',
          },
         
        ],
      },
      {
        BasicPlan:'Ultimate Plan',
        BasicPlanPrices:'5,000 Per user/Year',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Employee Tracking',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Task Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Attendance Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Leave Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Order Management',
            FeaturesFaIcon:'fa fa-check',
          },
         
          {
            labelFeatures:'Holiday Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Payroll',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Up to 5 Users',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Minimum 10 Employees',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Basic customer support',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Yearly',
            FeaturesFaIcon:'fa fa-check',
          },
         
        ],
      },
      {
        BasicPlan:'Premium Plan Pro',
        BasicPlanPrices:'5,500 Per user/Year',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Employee Tracking',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Task Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Attendance Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Leave Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Order Management',
            FeaturesFaIcon:'fa fa-check',
          },
         
          {
            labelFeatures:'Holiday Management',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Payroll',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Up to 20 Users',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Minimum 10 Employees',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Priority customer support',
            FeaturesFaIcon:'fa fa-check',
          },
          {
            labelFeatures:'Yearly',
            FeaturesFaIcon:'fa fa-check',
          },
         
        ],
      },
    ],
  },
 
  {
    id: "reptile-hisabbook",
    HeaderImages:'/Asstes/Images/AboutImages/HISAB_BOOK.png',
    TrackoImages: "/Asstes/Images/Services/background_gola_3 (2).png",
    TrackoName: "HISAB BOOK",
    HeadingFeatures:'Track your Daily hisab easily',
    TrackoInformation:'User-friendly mobile application designed to replace traditional notebooks, providing an efficient & seamless way to keep track of daily accounts.',
    AboutProductsInformation:'With Hisab Book, an intuitive and feature-rich personal finance management app, you can take charge of your money and utilize it to make wise decisions, reach your financial objectives, and feel less stressed. You can easily track your income, expenses, and savings with Hisab Book, giving you a thorough picture of your financial situation. Our user-friendly interface ensures a seamless experience by making it easy to explore via numerous functions.',
    AboutImages:'/Asstes/Images/AboutImages/HishBook.png',
    Features1InforImages:'/Asstes/Images/AboutImages/HishBook (1).png',
    Features2InforImages:'/Asstes/Images/AboutImages/HishBook (3).png',
    Features3InforImages:'/Asstes/Images/AboutImages/HishBook (2).png',
    FeaturesInforHeading1:'Expense Tracking',
    FeaturesInforHeading2:'Invoice Management',
    FeaturesInforHeading3:'Report & Analytics',
    Features1Infor:'Expense tracking Precisely document and arrange your spending, ranging from regular payments and everyday purchases to one-time capital outlays and corporate outlays. Keep tabs on every dollar you spend to get a clear picture of your financial situation.',
    Features2Infor:'Invoice Management Use the robust invoice management tools in Hisab Book to expedite the invoicing process. You can easily produce invoices that look professional, manage their status, and modify templates to fit your brand. Transmit bills online to guarantee prompt payment and cut down on administrative work.',
    Features3Infor:'Reports and Analytics: With customisable reports and analytics, you may learn important things about your financial performance. Hisab Book provides a wealth of reporting choices to enable you to gain a deep understanding of your financial data.',
    DesignImages3:'/Asstes/Images/AboutImages/background_hisab_3.png',
     DesignImages4:'/Asstes/Images/AboutImages/HishabBanner.png',
     Features1:'Offline Access',
     Features2:'Daily Summary Reports',
     Features3:'Expense Tracking',
     Features4:'Transaction Recording',
     Features5:'Invoice Generate',
     Features6:'24/7 Support',
     ProductImages:'/Asstes/Images/AboutImages/science 3.svg',
    PlaystoreImages:'/Asstes/Images/AboutImages/HisabBook.png',
    AppStoreImages:'/Asstes/Images/AboutImages/ReptuleEmiApp.png',
    PlanHeading:' CHOOSE YOUR PLAN',
    PlanButtonMonth:'Monthly',
    PlanButtonYeayly:null,
    PlanSubscriptionsMonth:[
      {
        BasicPlan:'1-Year Subscription',
        BasicPlanPrices:'500 / 1 Year',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        
        Features:[
          {
            labelFeatures:'Access to all features of the app',
            FeaturesFaIcon:'fa fa-check',
          },
         
        ],
        
      },
      {
        BasicPlan:'3-Year Subscription',
        BasicPlanPrices:'1000 /3 Years',
        CanselPrices:'1500',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Access to all features of the app',
            FeaturesFaIcon:'fa fa-check',
          },
          
        ],
      },
      {
        BasicPlan:'Lifetime Subscription',
        BasicPlanPrices:'1500/ Lifetime (One-time)',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Access to all features of the app',
            FeaturesFaIcon:'fa fa-check',
          },
         
        ],
      },
    ],
     




  } ,
  {
    id: "reptile-antitheft",
    HeaderImages:'/Asstes/Images/AboutImages/ANTI_THEFT.png',
    TrackoImages: "/Asstes/Images/Services/background_gola_3 (3).png",
    TrackoName: "ANTI THEFT",
    HeadingFeatures:'Take decisive action to secure your device',
    TrackoInformation:'The Anti-Theft Application aims to deter theft, provide real-time alerts, & enable users to take action against device theft or unauthorized access',
    AboutProductsInformation:'Our cell phones, which save our contacts, sensitive data, and personal information, have become our constant companions in the digital age. Regretfully, there is always a chance of theft. Our anti-theft application fills that need. Our program, which is made to protect your smartphone and its contents, has a number of strong features that can prevent theft and assist you in getting your device back if it is lost or stolen.',
    AboutImages:'/Asstes/Images/AboutImages/Anithef.png',
    Features1InforImages:'/Asstes/Images/AboutImages/AniTheft (1).png',
    Features2InforImages:'/Asstes/Images/AboutImages/AniTheft (3).png',
    Features3InforImages:'/Asstes/Images/AboutImages/AniTheft (2).png',
    FeaturesInforHeading1:'Intruder Selfie',
    FeaturesInforHeading2:'Shutdown Prevention',
    FeaturesInforHeading3:'Phone Tracking',
    Features1Infor:'The Intruder Selfie is one of our anti-theft applications most notable features. With the help of this creative feature, you may take a picture of anyone trying to access your smartphone without authorization.',
    Features2Infor:'Shutdown Prevention is another essential feature that keeps hackers from turning off your device. The app prevents someone from trying to turn off your phone by alerting you and maintaining the devices functionality.',
    Features3Infor:'A crucial part of our anti-theft program is phone tracking. This feature uses GPS technology to determine your Phone precise position in the terrible event that it is stolen.',
    DesignImages3:'/Asstes/Images/AboutImages/background_anti_3.png',
    DesignImages4:'/Asstes/Images/AboutImages/AntiBanner.png',
    Features1:'Intruder Selfie',
    Features2:'Shutdown Prevention',
    Features3:'Motion Alert',
    Features4:'Unplug Alert',
    Features5:'Charger Remove Detection',
    Features6:'Emergency Alert',
    ProductImages:'/Asstes/Images/AboutImages/science 3.svg',
    PlaystoreImages:'/Asstes/Images/AboutImages/Anti.png',
    AppStoreImages:'/Asstes/Images/AboutImages/ReptuleEmiApp.png',
    HrefLinkPlay:'https://play.google.com/store/apps/details?id=com.careconetechnologies.reptiletrackinfo&hl=en',
    PlanHeading:' CHOOSE YOUR PLAN',
    PlanButtonMonth:'Monthly',
    PlanButtonYeayly:null,
    PlanSubscriptionsMonth:[
      {
        BasicPlan:'1 Year',
        BasicPlanPrices:'500 + including GST ',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        
        Features:[
          {
            labelFeatures:'Access to all features of the software ',
            FeaturesFaIcon:'fa fa-check',
          },
         
        ],
        
      },
      {
        BasicPlan:'3 Year',
        BasicPlanPrices:'1000 + including GST',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Access to all features of the software ',
            FeaturesFaIcon:'fa fa-check',
          },
          
        ],
      },
    ],
  } ,
  {
    id: "reptile-dailyhisab",
    HeaderImages:'/Asstes/Images/AboutImages/Daily_Hisab.png',
    TrackoImages: "/Asstes/Images/AboutImages/DillyHisab-transformed.jpeg",
    TrackoName: "DAILY HISAB",
    HeadingFeatures:'EMPOWER YOUR BUSINESS WITH THE RIGHT TOOLS',
    TrackoInformation:'Are you tired of losing track of your costs and feeling overwhelmed by your finances? Your financial life can be made simpler with Daily Hisab. ',
    AboutProductsInformation:'Are you tired of losing track of your costs and feeling overwhelmed by your finances? Your financial life can be made simpler with Daily Hisab. You can simply track your income, expenses, and savings in one location with our user-friendly app, providing you with a comprehensive picture of your financial situation. No more hunting for receipts or trying to recall where your money went. By giving you financial control, Daily Hisab enables you to reach your financial objectives and make wise decisions.',
    AboutImages:'/Asstes/Images/AboutImages/AboutDialy.png',
    Features1InforImages:'/Asstes/Images/AboutImages/Daily_hisab_images.png',
    Features2InforImages:'/Asstes/Images/AboutImages/Dialy_hisab2.png',
    Features3InforImages:'/Asstes/Images/AboutImages/Dialy_hisab_3 (2).png',
    FeaturesInforHeading1:'Intuitive Interface',
    FeaturesInforHeading2:'Customizable Categories',
    FeaturesInforHeading3:'Budget Tracking',
    Features1Infor:'We made our software so that even non-techies could easily use it. Daily Hisab clear and straightforward structure makes it easy to navigate and locate the information you need. ',
    Features2Infor:'To keep track of your spending in a way that works for you, create and modify your own categories. Whether you are looking to keep tabs on your food bills, restaurant bills, or subscriptions, Daily Hisab lets you make categories that are specific to your budget. ',
    Features3Infor:'Set daily, weekly, or monthly budgets and track your progress to keep within your financial goals. You can set reasonable spending caps for several categories with Daily Hisab budget tracking feature, then keep an eye on your expenditures in relation to those caps.',
    DesignImages3:'/Asstes/Images/AboutImages/background_dhb_3.png',
    DesignImages4:'/Asstes/Images/AboutImages/Banner_hisab.png',
    Features1:'Intuitive Interface',
    Features2:'Reports and Analytics',
    Features3:'Categorization',
    Features4:'Stock Alerts',
    Features5:'Customizable Categories',
    Features6:'Budget Tracking',
     PlaystoreImages:'/Asstes/Images/AboutImages/Design1.png',
    AppStoreImages:'/Asstes/Images/AboutImages/Design2.png',
    ProductImages:'/Asstes/Images/AboutImages/science 3.svg'  ,
    PlanHeading:' CHOOSE YOUR PLAN',
    PlanButtonMonth:'Monthly',
    PlanButtonYeayly:null,
    PlanSubscriptionsMonth:[
      {
        BasicPlan:'1 Month',
        BasicPlanPrices:'1500 + GST',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        
        Features:[
          {
            labelFeatures:'Access to all features of the software ',
            FeaturesFaIcon:'fa fa-check',
          },
         
        ],
        
      },
      {
        BasicPlan:'1 Year',
        BasicPlanPrices:'16500 + GST',
        CanselPrices:'18000',
        BasicPlanIcon:'fa fa-inr',
        BasicButton:'Get Start',
        BasicFeatures:'Features',
        Features:[
          {
            labelFeatures:'Access to all features of the software ',
            FeaturesFaIcon:'fa fa-check',
          },
          
        ],
      },
    ],
  } ,
  {
    id: "reptile-emi-security",
    HeaderImages:'/Asstes/Images/AboutImages/EMI_SECURITY.png',
    TrackoImages: "/Asstes/Images/Services/background_gola_3.png",
    TrackoName: "EMI SECURITY",
    HeadingFeatures:'Secure Your Payment With Emi Security ',
    TrackoInformation:'Your one-stop shop for organizing and safeguarding your EMI payments. Reptile lets you protect your financial activities.',
    AboutImages:'/Asstes/Images/AboutImages/Emi.png',
    AboutProductsInformation:'This is particularly valid for financial transactions—like Equated Monthly Installments (EMIs)—and other financial transactions. The volume of financial transactions conducted online is increasing, which also raises the possibility of data breaches and cyberattacks. Understanding the value of protecting your sensitive data, Carecone Technologies Pvt. Ltd. has created an extensive EMI security program that guards against unwanted access to your financial and personal information.',
    Features1Infor:'Configure personalized reminders based on your unique requirements. There are other alternatives to select from, such as custom intervals monthly intervals. To make sure you never forget a crucial payment, you may also create reminders for particular dates or occasions, including birthdays or anniversaries.',
    Features1InforImages:'/Asstes/Images/AboutImages/EmiImagesFeatures (1).png',
    Features2InforImages:'/Asstes/Images/AboutImages/EmiImagesFeatures (2).png',
    Features3InforImages:'/Asstes/Images/AboutImages/EmiImagesFeatures (3).png',
    FeaturesInforHeading1:'Emi Reminder',
    FeaturesInforHeading2:'Emi Tracking',
    FeaturesInforHeading3:'24x7 Support',
    Features2Infor:'Keep an eye on your interest, balances, and EMI payments. With the help of our app, which offers a thorough financial health assessment, you can monitor your debt payback progress.',
    Features3Infor:'We at EMI Security Locker Application are dedicated to giving you the best possible service at any time. To ensure that you always have someone to help you with any questions, problems, or difficulties pertaining to your locker, we provide 24/7 customer service.',
    DesignImages3:'/Asstes/Images/AboutImages/background_security_3.png',
    DesignImages4:'/Asstes/Images/AboutImages/Security.png',
    Features1:'EMI Reminders',
    Features2:'Security Measures',
    Features3:'Alert System',
    Features4:'Lock/unlock feature',
    Features5:'24/7 Support',
    Features6:'Customer Location Fetch',
    ProductImages:'/Asstes/Images/AboutImages/science 3.svg',
     HrefLinkPlay:'https://play.google.com/store/apps/details?id=com.reptilesecurityplus.seller&hl=en',
    HrefLinkApp:'https://apps.apple.com/in/app/reptile-security/id6477824290',
    PlaystoreImages:'/Asstes/Images/AboutImages/ReptileEmiPlay.png',
    AppStoreImages:'/Asstes/Images/AboutImages/ReptuleEmiApp.png',
  
  } ,
  {
    id: "enterprise-mobility-management",
    HeaderImages:'/Asstes/Images/AboutImages/Emiiii.png',
    TrackoImages: "/Asstes/Images/AboutImages/background_EMM_3.png",
    TrackoName: "ENTERPRISE MOBILITY MANAGEMENT",
    HeadingFeatures:'Empower your team with the right tools.',
    TrackoInformation:'Businesses are embracing mobile technology more and more to improve productivity and streamline operations in todays fast-paced digital world.',
    AboutProductsInformation:'Enterprise Mobility Management (EMM) is a full-featured solution that helps companies to securely and efficiently manage their employees mobile devices, enabling them to remain connected and productive while they are on the move. With the help of EMM, employees can manage and administer company-owned or Bring Your Own Device (BYOD) devices from a single, centralized location, protecting sensitive data and granting access to the tools they need to do their jobs well.',
    AboutImages:'/Asstes/Images/AboutImages/EmmImages.png',
    Features1InforImages:'/Asstes/Images/AboutImages/EmmMobile1.png',
    Features2InforImages:'/Asstes/Images/AboutImages/EmmMobileImages3.png',
    Features3InforImages:'/Asstes/Images/AboutImages/EmmMobile2.png',
    FeaturesInforHeading1:'App Management',
    FeaturesInforHeading2:'Analytics And Reporting',
    FeaturesInforHeading3:'Support for Multiple Platforms',
    Features1Infor:'App Management: Enterprise Mobility Management (EMM) enables businesses to have precise control over which apps can be loaded on their devices. This includes the capacity to distribute authorized apps, add particular apps to a whitelist, and remove from the list any apps that are thought to be harmful or improper.',
    Features2Infor:'Analytics and Reporting: Enterprise Mobility Management (EMM) provides an extensive range of analytical and reporting features that deliver insightful information on app performance, device usage, and security compliance.',
    Features3Infor:'Support for Multiple Platforms: To guarantee that all device inside the company are controlled uniformly, EMM offers support for a number of mobile platforms, including iOS, Android, and Windows Phone.',
    DesignImages3:'/Asstes/Images/AboutImages/background_EMM_3.png',
    DesignImages4:'/Asstes/Images/AboutImages/BannerEmm.png',
    Features1:'Bulk Device Enrollment',
    Features2:'Geofencing/Location Tracking',
    Features3:'Application Management',
    Features4:'Device Policy Management',
    Features5:'Kiosk Mode',
    Features6:'Device Security',
     PlaystoreImages:'/Asstes/Images/AboutImages/EmmDesign2.png',
    AppStoreImages:'/Asstes/Images/AboutImages/EmmDesign3.png',
    ProductImages:'/Asstes/Images/AboutImages/science 3.svg'  ,
  } ,
 
];
