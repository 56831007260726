import Navbar from "./Navbar";
import Footer from "./Footer";
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
const MobileButton = [
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "OUR MISSION",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "OUR MISSION",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "OUR MISSION",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "OUR MISSION",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "OUR MISSION",
  },
  {
    Images: "/Asstes/Images/AboutImages/science 3.svg",
    label: "OUR MISSION",
  },
];
const Tracko = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  const location = useLocation();
  const {id} = useParams();
  const ProData = location.state?.ProData;
  console.log(ProData)
  if(!ProData){
    return(
      <div>
        <h1>{id}</h1>
      </div>
    )
  }

  return (
    <div>
      <Navbar />
      <div className="container PortFolio_section_main"data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
        <div className="row">
          <div className="col-12 PortFlio_section">
            <img src="/Asstes/Images/AboutImages/Portfolio.png" alt="images" />
          </div>
        </div>
      </div>
      <section className="Product_section_main"data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
        <div className="container Product_section">
          <div className="row">
            <div className="col-6 Product_section_text">
              <div className="TExt_services_section">
                <h4 style={{ textAlign: "left" }}>- WHO WE ARE</h4>
                <h3 style={{ textAlign: "left", paddingBottom: "20px" }}>
                  ABOUT OUR COMPANY
                </h3>
              </div>
              <p>
                Figma ipsum component variant main layer. Duplicate vector
                variant star outline background figma selection hand. Team bold
                opacity distribute fill selection variant. Subtract draft link
                hand main library polygon device background. Bullet invite auto
                background image. Community line edit frame variant reesizing
                inspect draft style. Link figjam text main component slice
                flows.
              </p>
            </div>
            <div className="col-6 Images_text_section">
              <div className="Product_images">
                <img
                  src="/Asstes/Images/AboutImages/iPhone 15 Pro.png"
                  alt="images"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
        <div className="Mobile_section_Mission">
          <div className="TExt_services_section">
            <h4>-Services</h4>
            <h3 style={{ paddingBottom: "20px" }}>
              WHY YOU NEED THIS SERVICES
            </h3>
          </div>
          <div className="container">
            <div className="row">
              {MobileButton.map((item, index) => (
                <div className="col-4" key={index}>
                  <div>
                    <img src={item.Images} alt="images" />
                    <p>{item.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container Product_images_section_main">
          <div className="row">
            <div className="col-12 product_images_sec">
              <img
                src="/Asstes/Images/AboutImages/Rectangle 192.png"
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>
      
      <section className="Product_main_mobile_section_pro"data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
        <div className="container Mobile_product_pages">
            <div className="row">
                <div className="col-12 Mobile_section_product">
                   <div className="Product_sec">
                    <div className="Over_flow_product">
                        <img src="/Asstes/Images/AboutImages/[Mockup] iPhone 21 (1).png" alt="images" />
                        </div>
                       <p>Figma ipsum component variant main layer. Duplicate vector variant star outline background figma selection hand. Team bold opacity distribute fill selection variant.Figma ipsum component variant main layer. Duplicate vector variant star outline background figma selection hand. Team bold opacity distribute fill selection variant.  </p>
                        </div> 
                </div>
                
            </div>
            <div className="row">
                <div className="col-12 Mobile_section_product">
                   <div className="Product_sec">
                       <p style={{paddingLeft:'20px',width:'70%'
                       }}>Figma ipsum component variant main layer. Duplicate vector variant star outline background figma selection hand. Team bold opacity distribute fill selection variant.Figma ipsum component variant main layer. Duplicate vector variant star outline background figma selection hand. Team bold opacity distribute fill selection variant.  </p>
                       <div className="Over_flow_product"style={{paddingLeft:'700px'}}>
                        <img src="/Asstes/Images/AboutImages/[Mockup] iPhone 21 (1).png" alt="images" />
                        </div>
                        </div> 
                </div>
                
            </div>

            <div className="row">
                <div className="col-12 Mobile_section_product">
                   <div className="Product_sec">
                    <div className="Over_flow_product">
                        <img src="/Asstes/Images/AboutImages/[Mockup] iPhone 21 (1).png" alt="images" />
                        </div>
                       <p>Figma ipsum component variant main layer. Duplicate vector variant star outline background figma selection hand. Team bold opacity distribute fill selection variant.Figma ipsum component variant main layer. Duplicate vector variant star outline background figma selection hand. Team bold opacity distribute fill selection variant.  </p>
                        </div> 
                </div>
                
            </div>
        </div>
      </section>
      <div
        className="container PortFolio_section_main_Deatils"
        style={{ marginTop: "50px" }}
        data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
        <div className="row">
          <div
            className="col-6 PortFlio_section_deatilssss"
            style={{ paddingRight: "10px" }}
          >
            <img
              src="/Asstes/Images/AboutImages/Rectangle 186.png"
              alt="images"
            />
          </div>
          <div
            className="col-6 PortFlio_section_deatilssss"
            style={{ paddingLeft: "10px" }}
          >
            <img
              src="/Asstes/Images/AboutImages/Group 1000006177.png"
              alt="images"
            />
          </div>
        </div>
      </div>
      <div className="container PortFolio_section_main_Deatils"data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
        <div className="row">
          <div className="col-12 PortFlio_section_deatils">
            <img
              src="/Asstes/Images/AboutImages/Rectangle 188.png"
              alt="images"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Tracko;
