import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "./Navbar";
const Thanku = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const FooterData = {
    LinkFooter: [
      { label: "Home", href: "/" },
      { label: "About", href: "/About" },
      { label: "Services", href: "#Services" },
      { label: "Products", href: "/Product" },
      { label: "PortFolio", href: "/Portfolio" },
      { label: "Contacts", href: "#Contact" },
    ],
    servicesLink: [
      { label: "Software Development", href: "/SoftwareDevelopment" },
      { label: "App Development", href: "/Mobile" },
      { label: "Web Development", href: "/WebDevelopment" },
      { label: "Branding Services", href: "/BrandingServices" },
      { label: "Digital Marketing", href: "/DigitalMarket" },
      { label: "Cloud computing Services", href: "/Cloud" },
    ],
  };
  return (
    <div>
      <Navbar />
      <div
        className="Contact_section_main"
        id="Contact"
        style={{ paddingTop: "250px", paddingBottom: "0" }}
      >
        <div className="container Contact_container">
          <center>
            <img src="/Asstes/Images/imgpsh_fullsize_anim.gif" alt="Thanku" />
            <h2>Thank You for Reaching Out</h2>
          </center>
        </div>
      </div>

      <section className="GetQueate_section">
        <div
          className="container GetQueate_section_section_one"
        >
          <div className="row">
            <div className="col-12"></div>
          </div>
        </div>
      </section>
      <div className="Footer_main_section">
        <div className="container Footer_section">
          <div className="row">
            <div className="col-lg-6 Footer_card_section">
              <div className="Card_main">
                <img src="/Asstes/Images/email.svg" alt="images"/>
                <div>
                  <p>
                    <a
                      href="mailto:info@reptileindia.co.in"
                      style={{ color: "white" }}
                    >
                      info@reptileindia.co.in
                    </a>
                  </p>
                  <p>
                    {" "}
                    <a
                      href="mailto:nidhi@reptileindia.co.in"
                      style={{ color: "white" }}
                    >
                      nidhi@reptileindia.co.in
                    </a>{" "}
                  </p>
                </div>
              </div>
              <div className="Card_main">
                <img src="/Asstes/Images/Call.gif" alt='images'style={{ width: "50px" }} />
                <div className="Number">
                  <a href="tel:+919311572747" style={{ color: "white" }}>
                    <p>(+91)9311572747</p>
                  </a>
                
                  <a href="tel:+918178334747" style={{ color: "white" }}>
                <p> (+91)8178334747</p>
                  </a>
                </div>
              </div>
              <div
                className="Card_main"
                id="Card_Messages"
                style={{ paddingTop: "20px;", paddingBottom: "20px" }}
              >
                <img src="/Asstes/Images/location.svg" alt="images"/>
                <div>
                  <a href="https://www.google.com/search?q=carecone+technologies+private+limited&rlz=1C1RXQR_enIN1101IN1101&oq=&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MhEIARBFGDkYQxixAxiABBiKBTIGCAIQRRg7Mg8IAxAAGEMYsQMYgAQYigUyDwgEEAAYQxixAxiABBiKBTIMCAUQABhDGIAEGIoFMgwIBhAAGEMYgAQYigUyDQgHEAAYgwEYsQMYgAQyDAgIEAAYQxiABBiKBTIPCAkQABhDGLEDGIAEGIoF0gEIMjE2OGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8">
                  <p>
                    Carecone Technologies private limited 265, 2nd floor,
                    Aggarwal City Plaza, Plot No.-17, Mangalam Place, Sector-3
                    Rohini, New Delhi-110085
                  </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 QuckLink_section_footer">
              <div className="row">
                <div className="col-lg-2 QuckLink_section_footer_first">
                  <h5>Menu</h5>
                  {FooterData.LinkFooter.map((item, index) => (
                    <ul key={index}>
                      <Link to={item.href}>
                        <li>{item.label}</li>{" "}
                      </Link>
                    </ul>
                  ))}
                </div>
                <div className="col-lg-6 QuckLink_section_footer_secound">
                  <h5>Services</h5>
                  {FooterData.servicesLink.map((item, index) => (
                    <ul key={index}>
                      <Link to={item.href}>
                        <li>{item.label}</li>{" "}
                      </Link>
                    </ul>
                  ))}
                </div>
                <div className="col-lg-4 QuckLink_section_footer_thard">
                  <h5>Other</h5>
                  <ul>
                    <Link to="/privacy-policy">
                      <li>Privacy Policy</li>
                    </Link>
                    <Link to="/terms-condition">
                      <li>Terms & Condition</li>
                    </Link>
                    <Link to="/refund-policy">
                      <li>Refund Policy</li>
                    </Link>

                  </ul>
                </div>
              </div>
              <div className="Footer_images_flow_section">
                <div className="Footer_images">
                  <a
                    href="https://www.youtube.com/@ReptileIndia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/youtube.svg" alt="YouTube" />
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=9311572747"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/whatsapp.svg" alt="WhatsApp" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/reptileitservice/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/Linkdin.svg" alt="LinkedIn" />
                  </a>
                  <a
                    href="https://www.instagram.com/reptileitservice/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/instagram.svg" alt="Instagram" />
                  </a>
                  <a
                    href="https://www.facebook.com/reptileitservice/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/facebook.svg" alt="Facebook" />
                  </a>
                  <a
                    href="https://x.com/reptileindia?mx=2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/Twitter.svg" alt="Twitter" />
                  </a>
                </div>

                <div className="Logo_footer_section">
                  <img src="/Asstes/Images/LogoFooter.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Copy_right_section">
          <div className="container Copy_right">
            <div className="row">
              <div className="col-12">
                <p>© 2020 Created by: Carecone Technologies Pvt Ltd</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Thanku;
