import Navbar from "./Navbar";
import Footer from "./Footer";
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
const ProductDeatils = () => {
  const [activeButton, setActiveButton] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  const location = useLocation();
  const {id} = useParams();
  const ProData = location.state?.ProData;
  console.log(ProData)
  if(!ProData){
    return(
      <div>
        <h1>{id}</h1>
      </div>
    )
  }

  const handleClick = (index) => {
    setActiveButton(index);
  };

  return (
    <div>
      <Navbar />
      <div className="container PortFolio_section_main">
        <div className="row">
          <div className="col-12 PortFlio_section">
            <img src={ProData.HeaderImages} alt="images" />
          </div>
        </div>
      </div>
      <section className="Product_section_main">
        <div className="container Product_section">
          <div className="row">
            <div className="col-lg-6 Product_section_text">
              <div className="TExt_services_section">
                <h5 style={{ textAlign: "left",color:'#F58327' }}>WHO WE ARE</h5>
                <h4 style={{ textAlign: "left", paddingBottom: "20px",color:'white' }}>
                ABOUT {ProData.TrackoName}
                </h4>
              </div>
              <p>{ProData.AboutProductsInformation}</p>
            </div>
            <div className="col-lg-6 Images_text_section">
              <div className="Product_images">
                <img
                  src={ProData.AboutImages}
                  alt="images"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section >
        <div className="Mobile_section_Mission">
          <div className="TExt_services_section">
            <h4>Our Features</h4>
            <h3 style={{ paddingBottom: "20px",textTransform:'uppercase',fontSize:'29px' }}>
          {ProData.HeadingFeatures}
            </h3>
          </div>
          <div className="container">
            <div className="row Products_Cat_work_section">
              <div className="col-lg-4 Products_div_buton_section">
                <div className="Images_products">
                  <img src={ProData.ProductImages} alt="images"/>
                  <p>{ProData.Features1}</p>
                </div>
              </div>
              <div className="col-lg-4 Products_div_buton_section">
                 <div className="Images_products">
                  <img src={ProData.ProductImages} alt="images"/>
                  <p>{ProData.Features2}</p>
                </div>
              </div>
              <div className="col-lg-4 Products_div_buton_section">
                 <div className="Images_products">
                  <img src={ProData.ProductImages} alt="images"/>
                  <p>{ProData.Features3}</p>
                </div>
              </div>
            </div>
            <div className="row Products_Cat_work_section">
              <div className="col-lg-4 Products_div_buton_section">
                 <div className="Images_products">
                  <img src={ProData.ProductImages} alt="images"/>
                  <p>{ProData.Features4}</p>
                </div>
              </div>
              <div className="col-lg-4 Products_div_buton_section">
                 <div className="Images_products">
                  <img src={ProData.ProductImages} alt="images"/>
                  <p>{ProData.Features5}</p>
                </div>
              </div>
              <div className="col-lg-4 Products_div_buton_section">
                 <div className="Images_products">
                  <img src={ProData.ProductImages} alt="images"/>
                  <p>{ProData.Features6}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      

      <div className="Images_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src={ProData.DesignImages4} alt="images" />
            </div>
          </div>
        </div>
      </div>
      <section className="Product_main_mobile_section_pro ProductS_desktop">
        <div className="container Mobile_product_pages">
            <div className="row">
                <div className="col-12 Mobile_section_product">
                   <div className="Product_sec">
                    <div className="Over_flow_product">
                        <img src={ProData.Features1InforImages} alt="images" />
                        </div>
                        <div className="Heading_section">
                        <h4>{ProData.FeaturesInforHeading1}</h4>
                       <p>{ProData.Features1Infor}</p>
                       </div>
                        </div> 
                </div>
                
            </div>
            <div className="row">
                <div className="col-12 Mobile_section_product">
                   <div className="Product_sec">
                    <div className="Heading_section">
                       <h3>{ProData.FeaturesInforHeading2}</h3>
                       <p style={{paddingLeft:'10px',width:'75%'
                       }}>{ProData.Features2Infor}</p>
                       </div>
                       <div className="Over_flow_product"style={{paddingLeft:'75%'}}>
                        <img src={ProData.Features2InforImages} alt="images" />
                        </div>
                        </div> 
                </div>
                
            </div>

            <div className="row">
                <div className="col-12 Mobile_section_product">
                   <div className="Product_sec">
                    <div className="Over_flow_product">
                        <img src={ProData.Features3InforImages} alt="images" />
                        </div>
                        <div className="Heading_section">
                        <h4>{ProData.FeaturesInforHeading3}</h4>
                       <p>{ProData.Features3Infor}</p>
                       </div>
                        </div> 
                </div>
                
            </div>
        </div>
      </section>

        <section className="Proudcts_Mobile_section_main">
          <div className="container">
            <div className="row">
            <div className="col-lg-6 Products_section">
            <img src={ProData.Features1InforImages} alt="images" />
            </div>
            <div className="col-lg-6 Products_section">
            
            <p>
            <h4 style={{paddingBottom:'15px'}}>{ProData.FeaturesInforHeading1}</h4>
              {ProData.Features1Infor}
            
            </p>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-6 Products_section">
            <img src={ProData.Features2InforImages} alt="images" />
            </div>
            <div className="col-lg-6 Products_section">
            <p>
            <h3 style={{paddingBottom:'15px',color:'#F58327'}}>{ProData.FeaturesInforHeading2}</h3>
              {ProData.Features2Infor}</p>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-6 Products_section">
            <img src={ProData.Features3InforImages} alt="images" />
            </div>
            <div className="col-lg-6 Products_section">
            <p>
            <h4 style={{paddingBottom:'15px'}}>{ProData.FeaturesInforHeading3}</h4>
              {ProData.Features3Infor}</p>
            </div>
            </div>
          </div>
        </section>

      <div
        className="container PortFolio_section_main_Deatils"
        style={{ marginTop: "50px" }}
        >
        <div className="row">
          
          <div
            className="col-lg-6 PortFlio_section_deatilss"
            style={{ paddingRight: "10px" }}
          >
            <a href={ProData.HrefLinkApp}target="_blank">
            <img
              src={ProData.AppStoreImages}
              alt="images"
            />
             </a>
          </div>
         
          
          <div
            className="col-lg-6 PortFlio_section_deatilss"
            style={{ paddingRight: "10px" }}
          >
            <a href={ProData.HrefLinkPlay}target="_blank" >
            <img
              src={ProData.PlaystoreImages}
              alt="images"
            />
            </a>
          </div>
        
      
         
        </div>
      </div>
      <div className="container PortFolio_section_main_Deatils">
        <div className="row">
          <div className="col-12 PortFlio_section_deatils">
            <img
              src={ProData.DesignImages3}
              alt="images"
            />
          </div>
        </div>
      </div>
        
      <div className="Suscription_section">
        <div className="container">
        <div className="Button_click_section">
  <h3>{ProData.PlanHeading}</h3>
  { (ProData.PlanButtonMonth || ProData.PlanButtonYeayly) && (  
    <div>
      <div className="Button_section">
        {ProData.PlanButtonMonth && (
          <p
            className={activeButton === 0 ? 'active' : ''} 
            onClick={() => handleClick(0)}
          >
            {ProData.PlanButtonMonth}
          </p>
        )}

        {ProData.PlanButtonYeayly && (
          <p 
            className={activeButton === 1 ? 'active' : ''} 
            onClick={() => handleClick(1)}
          >
            {ProData.PlanButtonYeayly}
          </p>
        )}
      </div>
    </div>
  )}
</div>


          <div className="row">

          {activeButton === 0 && (
         <div className="col-12 Montly_section">
          <div className="row">
          {
  ProData && ProData.PlanSubscriptionsMonth && ProData.PlanSubscriptionsMonth.length > 0 ? (
    ProData.PlanSubscriptionsMonth.map((item, index) => (
      <div className="col-lg-3 Basic_plan_section" key={index}>
        <div className="Basic_securption">
          <div className="Basic_plan_header">
            <h3>{item.BasicPlan}</h3>
            
            <h5><i className={item.BasicPlanIcon}style={{color:'#F58327'}}></i>        <del>{item.CanselPrices}</del> {item.BasicPlanPrices}
           
       
            </h5>
            <button>{item.BasicButton}</button>
          </div>
          <div className="Basic_plan_features">
            <h6>{item.BasicFeatures}</h6>
            {
              item.Features && item.Features.length > 0 && item.Features.map((feature, featureIndex) => (
                <div className="Features_icon" key={featureIndex}>
                  <i className={feature.FeaturesFaIcon}></i>
                  <p>{feature.labelFeatures}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    ))
  ) :null
}

       </div>
        </div>
      )}

        {activeButton === 1 && (
        <div className="col-12 Yearly_section">
        <div className="row">

        {
  ProData && ProData.PlanSubscriptionsYearly && ProData.PlanSubscriptionsYearly.length > 0 ? (
    ProData.PlanSubscriptionsYearly.map((item, index) => (
      <div className="col-lg-3 Basic_plan_section" key={index}>
        <div className="Basic_securption">
          <div className="Basic_plan_header">
            <h3>{item.BasicPlan}</h3>
            <h5>
              <i className={item.BasicPlanIcon}style={{color:'#F58327'}}></i>
               {item.BasicPlanPrices}
               
            </h5>
            <button>{item.BasicButton}</button>
          </div>
          <div className="Basic_plan_features">
            <h6>{item.BasicFeatures}</h6>
            {
              item.Features && item.Features.length > 0 && item.Features.map((feature, featureIndex) => (
                <div className="Features_icon" key={featureIndex}>
                  <i className={feature.FeaturesFaIcon}></i>
                  <p>{feature.labelFeatures}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    ))
  ) :null
}
       

       
        </div>
        </div>
      )}
      
         </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ProductDeatils;
