import '../App.css'
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
const Navbar = ()=>{
  const location = useLocation();
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const [affix, setAffix] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setAffix(true);
    } else {
      setAffix(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const Menus= [
        {
            label:'Home',
            href:'/',
        },
        {
            label:'About us',
            href:'/about-us',
        },
        { label: 'Services', href: '/services' },
        , {
            label:'Portfolio',
            href:'/our-portfolio',
        },
        {
            label:'Products',
            href:'/our-products',
        },
        {
            label:'Contact',
            href:'/contact-us',
        }
    ]
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    return(
    <div>
 <section className={`Navbar_section_main ${affix ? 'affix' : ''}`}>
        <div className='container'>
       <div className='row'>
       <div className='col-12'style={{padding:'0',margin:'0'}}>
       <div className="container nav_container">
    <div className="brand">
      <a href="https://www.reptileindia.co.in">
        <img src='/Asstes/Images/Logo.png' alt='Logo'/>
      </a>
    </div>
    <div className='Navbar_items'>
    {
        Menus.map((item,index)=>(
            <ul class="nav-list"key={index}>
       <li className={location.pathname === item.href ? 'active' : ''}>
        {item.href.startsWith('#') ? (
          <a href={item.href}>{item.label}</a> 
        ) : (
          <Link to={item.href}>{item.label}</Link> 
        )}
      </li>
    </ul>
        ))
    }
    </div>
    <Link to='/contact-us'>
    <div className='Navbar_botton'>
    <img src='/Asstes/Images/LogoButton.png' alt='LogoButton'/>
        <button>
            GET QUOTE
            <img src='/Asstes/Images/RightArrow.svg' alt='images' />
        </button>
    </div>
    </Link>
  </div>
       </div>
       </div>
        </div>
 </section>





 <nav className={`navbar ${affix ? 'affix' : ''}`} style={{background: '#0A0A0A', backdropFilter: 'blur(20px)'}}>
      <div className="container Mobile_navbar">
        <div className="brand">
          <a href="https://www.reptileindia.co.in">
            <img src='/Asstes/Images/Logo.png' alt='Logo' />
          </a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
        <div className={`nav-elements Navbar_items ${showNavbar && 'active'}`}>
          <ul className="nav-list">
            {Menus.map((item, index) => (
              <li key={index} className={location.pathname === item.href ? 'active' : ''}>
                {item.href.startsWith('#') ? (
                  <a href={item.href}>{item.label}</a>
                ) : (
                  <Link to={item.href} >{item.label}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>







  </div>
    )
}
export default Navbar;