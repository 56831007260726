import { useState } from "react";
import "../App.css";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
const Contact = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const FooterData = {
    LinkFooter: [
      { label: "Home", href: "/" },
      { label: "About", href: "/about-us" },
      { label: "Services", href: "/services" },
      { label: "Products", href: "/our-products" },
      { label: "Portfolio", href: "/our-portfolio" },
      { label: "Contacts", href: "/contact-us" },
    ],
    servicesLink: [
      { label: "Software Development", href: "/services/software-development" },
      { label: "App Development", href: "/services/app-development" },
      { label: "Web Development", href: "/services/web-development" },
      { label: "Branding Services", href: "/services/branding-services" },
      { label: "Digital Marketing", href: "/services/digital-marketing" },
      { label: "Cloud Computing Services", href: "/services/cloud-services" },
    ],
  };
  const [FullName, setFullName] = useState("");
  const [Number, setNumber] = useState("");
  const [Message, setMessage] = useState("");

  const ContactUs = (e) => {
    e.preventDefault();
    console.log(FullName, Number, Message);
    axios
      .post(
        "https://www.reptileindia.co.in/laravel_website/api/website_send_email",
        {
          name: FullName,
          phone_number: Number,
          message: Message,
        }
      )
      .then((res) => {
        console.log("Response:", res);

        if (res.data.result) {
          navigate("/Thanku");
          setFullName("");
          setNumber("");
          setMessage("");
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.error("Error sending email:", err);
      });
  };
  return (
    <div>
      <Navbar />
      <div
        className="Contact_section_main"
        id="Contact"
        style={{ paddingTop: "150px", paddingBottom: "0" }}
       
      >
        <div className="container Contact_container">
          <div className="row">
            <div className="col-lg-6 Contact_container_tetx">
              <h1>
                Lets <br></br> <span className="Span_text"> Talk</span>
              </h1>
            </div>
            <div className="col-lg-6 Form_section">
              <form onSubmit={ContactUs}>
                <input
                  type="text"
                  name="text"
                  className="FirstName"
                  placeholder="Name"
                  required
                  onChange={(e) => setFullName(e.target.value)}
                />
                <input
                  type="text"
                  name="number"
                  className="PhomeNumber"
                  placeholder="Phone No."
                  required
                  onChange={(e) => setNumber(e.target.value)}
                />
                <textarea
                id="Card_Messages"
                  className="Message"
                  placeholder="Message"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit" className="Submit_button">
                  GET QUOTE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <section
        className="GetQueate_section"
       
      >
        <div className="container GetQueate_section_section">
          <div className="row">
            <div className="col-12">
              <img
                src="/Asstes/Images/AboutImages/Group 1000006133.png"
                alt="Images"
              />
              <div className="OverFlow_GetQuate">
                <img src="/Asstes/Images/Running.gif" alt="Running" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="Footer_main_section"
       
      >
        <div className="container Footer_section">
          <div className="row">
            <div className="col-lg-6 Footer_card_section">
              <div className="Card_main"
              >
                <img src="/Asstes/Images/email.svg" />
                <div>
                  <p>
                    <a
                      href="mailto:info@reptileindia.co.in"
                      style={{ color: "white" }}
                    >
                      info@reptileindia.co.in
                    </a>
                  </p>
                  <p>
                    {" "}
                    <a
                      href="mailto:nidhi@reptileindia.co.in"
                      style={{ color: "white" }}
                    >
                      nidhi@reptileindia.co.in
                    </a>{" "}
                  </p>
                </div>
              </div>
              <div className="Card_main">
                <img src="/Asstes/Images/Call.gif" style={{ width: "50px" }} />
                <div>
              
                  <a href="tel:+919311572747" style={{ color: "white" }}>
                    (+91)9311572747
                  </a>
                  ,{" "}
                  <a href="tel:+918178334747" style={{ color: "white" }}>
                    (+91)8178334747
                  </a>
                </div>
              </div>
              <div
                className="Card_main"
                id="Card_Messages"
                style={{ paddingTop: "20px;", paddingBottom: "20px" }}
              >
                <img src="/Asstes/Images/location.svg" />
                <div>
                  <a href="https://www.google.com/search?q=carecone+technologies+private+limited&rlz=1C1RXQR_enIN1101IN1101&oq=&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MhEIARBFGDkYQxixAxiABBiKBTIGCAIQRRg7Mg8IAxAAGEMYsQMYgAQYigUyDwgEEAAYQxixAxiABBiKBTIMCAUQABhDGIAEGIoFMgwIBhAAGEMYgAQYigUyDQgHEAAYgwEYsQMYgAQyDAgIEAAYQxiABBiKBTIPCAkQABhDGLEDGIAEGIoF0gEIMjE2OGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8">
                    <p>
                      Carecone Technologies private limited 265, 2nd floor,
                      Aggarwal City Plaza, Plot No.-17, Mangalam Place, Sector-3
                      Rohini, New Delhi-110085
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 QuckLink_section_footer">
              <div className="row">
                <div className="col-2 QuckLink_section_footer_first">
                  <h5>Menu</h5>
                  {FooterData.LinkFooter.map((item, index) => (
                    <ul key={index}>
                      <Link to={item.href}>
                        <li>{item.label}</li>{" "}
                      </Link>
                    </ul>
                  ))}
                </div>
                <div className="col-lg-6 QuckLink_section_footer_secound">
                  <h5>Services</h5>
                  {FooterData.servicesLink.map((item, index) => (
                    <ul key={index}>
                      <Link to={item.href}>
                        <li>{item.label}</li>{" "}
                      </Link>
                    </ul>
                  ))}
                </div>
                <div className="col-lg-4 QuckLink_section_footer_thard">
                  <h5>Other</h5>
                  <ul>
                    <Link to="/privacy-policy">
                      <li>Privacy Policy</li>
                    </Link>
                    <Link to="/terms-condition">
                      <li>Terms & Condition</li>
                    </Link>
                    <Link to="/refund-policy">
                      <li>Refund Policy</li>
                    </Link>
                    {/* <Link to="/privacy-policy-anti-theft">
                      <li>Privacy policy anti-theft</li>
                    </Link>
              */}
                  </ul>
                </div>
              </div>
              <div className="Footer_images_flow_section">
                <div className="Footer_images">
                  <a
                    href="https://www.youtube.com/@ReptileIndia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/youtube.svg" alt="YouTube" />
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=9311572747"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/whatsapp.svg" alt="WhatsApp" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/reptileitservice/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/Linkdin.svg" alt="LinkedIn" />
                  </a>
                  <a
                    href="https://www.instagram.com/reptileitservice/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/instagram.svg" alt="Instagram" />
                  </a>
                  <a
                    href="https://www.facebook.com/reptileitservice/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/facebook.svg" alt="Facebook" />
                  </a>
                  <a
                    href="https://x.com/reptileindia?mx=2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/Asstes/Images/Twitter.svg" alt="Twitter" />
                  </a>
                </div>

                <div className="Logo_footer_section">
                  <img src="/Asstes/Images/LogoFooter.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Copy_right_section">
          <div className="container Copy_right">
            <div className="row">
              <div className="col-12">
                <p>© 2020 Created by: Carecone Technologies Pvt Ltd</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
