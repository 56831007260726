import Footer from "./Footer";
import Navbar from "./Navbar";
import Slider from "react-slick";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
const PortFolioDeatils = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    var singleData = location.state?.item;
    if (!singleData) {
      navigate("/Portfolio");
    }
    get_data_single_projects(singleData);
  }, []);
  const navigate = useNavigate();
  const [singleProjects, setsingleProjects] = useState({});

  function get_data_single_projects(singleData) {
    console.log(singleData);
    if (singleData.slug) {
      axios
        .post("https://www.reptileindia.co.in/laravel_website/api/projects", {
          slug: singleData.slug,
        })
        .then((res) => {
          if (res.data.result) {
            console.log("Response->project:", res.data.project);
            setsingleProjects(res.data.project);
          }
        })
        .catch((err) => {
          console.error("Error sending email:", err);
        });
    } else {
      return <div>No portfolio data found for slug</div>;
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    autoplaySpeed: 2000,
    verticalSwiping: true,
  };
  const location = useLocation();
  const { id } = useParams();
  const DataItem = location.state?.item;

  if (!DataItem || typeof DataItem !== "object") {
    return <div>No portfolio data found for ID: {id}</div>;
  }
  // const DataDisplay = ({ text }) => {
  //   console.log("text-->", text);
  //   const PortFolioData = text
  //     .split("")
  //     .map((char, index) =>
  //       char === " " ? (
  //         <div key={index}>&nbsp;</div>
  //       ) : (
  //         <div key={index}>{char}</div>
  //       )
  //     );

  //   return <div style={styles.words}>{PortFolioData}</div>;
  // };

  return (
    <div>
      <Navbar />
      <div
        className="container PortFolio_section_main"
       
      >
        <div className="row">
          <div className="col-12 PortFlio_section">
            {singleProjects.video ? (
              <video
                controls
                autoPlay
                loop
                muted
                playsInline
                style={{ width: "100%", height: "100%" }}
              >
                <source src={singleProjects.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div>No video available</div>
            )}
           
          </div>
          <div className="Heading_portfolio">
              <h1>{singleProjects.project_name}</h1>
            </div>
        </div>
      </div>
      <section
        className="portfolio_details"
       
      >
        <div className="container portFolio_details_pages">
          <div className="row">
            <div className="col-lg-6 portFolio_details_pages_pages">
              <div className="TExt_services_section">
                <h4 style={{ textAlign: "left" }}> {singleProjects.text}</h4>
                <h3 style={{ textAlign: "left", paddingBottom: "20px" }}>
                  ABOUT COMPANY
                </h3>
              </div>
              <p>{singleProjects.about}</p>
            </div>
            <div className="col-lg-6 Slider_portfolio_pages">
              <div className="slider_portfolio_section">
                <Slider {...settings}>
                  {singleProjects.image &&
                    singleProjects.image.slice(0, 1).map((imageUrl, index) => (
                      <div className="Slider_review_section" key={index}>
                        <div className="Slider_section_all">
                        <img
                          src={imageUrl}
                          alt={`Image ${index}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                        </div>
                      </div>
                    ))}
                     {singleProjects.image &&
                    singleProjects.image.slice(1, 2).map((imageUrl, index) => (
                      <div className="Slider_review_section" key={index}>
                        <div className="Slider_section_all">
                        <img
                          src={imageUrl}
                          alt={`Image ${index}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                        </div>
                      </div>
                    ))}
                     {singleProjects.image &&
                    singleProjects.image.slice(2, 3).map((imageUrl, index) => (
                      <div className="Slider_review_section" key={index}>
                        <div className="Slider_section_all">
                        <img
                          src={imageUrl}
                          alt={`Image ${index}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                        </div>
                      </div>
                    ))}
                     {singleProjects.image &&
                    singleProjects.image.slice(3, 4).map((imageUrl, index) => (
                      <div className="Slider_review_section" key={index}>
                        <div className="Slider_section_all">
                        <img
                          src={imageUrl}
                          alt={`Image ${index}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                        </div>
                      </div>
                    ))}
                     {singleProjects.image &&
                    singleProjects.image.slice(4, 5).map((imageUrl, index) => (
                      <div className="Slider_review_section" key={index}>
                        <div className="Slider_section_all">
                        <img
                          src={imageUrl}
                          alt={`Image ${index}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                        </div>
                      </div>
                    ))}
                </Slider>
                <div className="col-12 overflow_section_portflio"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container slider_portfolio_bttom"
       
      >
        <div className="row">
          <div className="col-12 text_bulid_portfolio">
            <h1>
              build<br></br>
              test<br></br>
              deploy
            </h1>
            <div className="Button_section_main_portfolio">
              {singleProjects.button &&
                singleProjects.button.map((item, index) => (
                  <button>
                    <img
                      src="/Asstes/Images/Services/science 3 (4).svg"
                      alt="images"
                    />

                    <button
                      key={index}
                      style={{ margin: "10px", padding: "10px" }}
                    >
                      {item}
                    </button>
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="container PortFolio_section_main_Deatils"
        style={{ marginTop: "50px" }}
       
      >
        <div className="row">
          {singleProjects.background_image &&
            singleProjects.background_image
              .slice(0, 2)
              .map((Images1, index) => (
                <div
                  className="col-lg-6 PortFlio_section_deatilssss"
                 
                  key={index}
                >
                  <img src={Images1} alt="images" />                                                                                    
                </div>
              ))}
        </div>
      </div>
      <div
        className="container PortFolio_section_main_Deatils"
       
      >
        <div className="row">
          {singleProjects.background_image &&
            singleProjects.background_image.slice(2, 3).map((Images, index) => (
              <div className="col-lg-12 PortFlio_section_deatils">
                <img src={Images} alt="images" />
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PortFolioDeatils;
