import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect } from 'react';
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./components/About";
import Mobile from "./components/MobileDev";
import Cloud from "./components/Cloud";
import DigitalMarket from "./components/Digital";
import BrandingServices from "./components/BrandingServices";
import WebDevelopment from "./components/WebDevelopment";
import SoftwareDevelopment from "./components/SoftwareDevelopmet";
import PortFolio from "./components/PortFolio";
import PortFolioDeatils from "./components/PortFolioDeatils";
import Contact from "./components/Contact";
import Thanku from "./components/ThankuPAges";
import Services from "./components/Services";
import AOS from 'aos';
import 'aos/dist/aos.css';
import PRODUCT from "./components/PRODUCT ";
import ProductDeatils from "./components/ProductDeatils";
import PrivacyPolicymobilesecurity from "./components/PrivacyPolicymobilesecurity";
import Privacypolicy from "./components/PRIVACY";
import Refundpolicy from "./components/Refundpolicy";
import Termcondition from "./components/Termcondition";
import Tracko from "./components/Tracko";




const App = ()=>{
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="Navbar" element={<Navbar />}></Route>
        <Route path="Footer" element={<Footer />}></Route> 
        <Route path="/" element={<Home />}></Route>
        <Route path="about-us" element={<About />}></Route>
        <Route path='/services/app-development' element={<Mobile />} ></Route>
        <Route path='/services/cloud-services' element={<Cloud />} ></Route>
        <Route path="/services/branding-services" element={<BrandingServices />}></Route>
        <Route path="/services/digital-marketing" element={<DigitalMarket />}></Route>
        <Route path="/services/web-development" element={<WebDevelopment />}></Route>
        <Route path="/services/software-development" element={<SoftwareDevelopment />}></Route>
        <Route path="our-products/:id" element={<ProductDeatils />}></Route>
        <Route path="our-products" element={<PRODUCT />}></Route>
        <Route path="tracko" element={<Tracko />}></Route>
        <Route path="our-portfolio" element={<PortFolio />} />
        <Route path="thanku" element={<Thanku />} />
        <Route path="services" element={<Services />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="privacy-policy" element={<Privacypolicy />} />
        <Route path="refund-policy" element={<Refundpolicy />} />
        <Route path="terms-condition" element={<Termcondition />} />
      <Route path="our-portfolio/:id" element={<PortFolioDeatils />} />
      <Route path="privacy-policy-anti-theft" element={<PrivacyPolicymobilesecurity />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;